exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ai-apps-library-js": () => import("./../../../src/pages/ai_apps_library.js" /* webpackChunkName: "component---src-pages-ai-apps-library-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-content-library-js": () => import("./../../../src/pages/content-library.js" /* webpackChunkName: "component---src-pages-content-library-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-guide-samples-form-js": () => import("./../../../src/pages/guide_samples_form.js" /* webpackChunkName: "component---src-pages-guide-samples-form-js" */),
  "component---src-pages-histology-js": () => import("./../../../src/pages/histology.js" /* webpackChunkName: "component---src-pages-histology-js" */),
  "component---src-pages-how-js": () => import("./../../../src/pages/how.js" /* webpackChunkName: "component---src-pages-how-js" */),
  "component---src-pages-immunostaining-if-js": () => import("./../../../src/pages/immunostaining/if.js" /* webpackChunkName: "component---src-pages-immunostaining-if-js" */),
  "component---src-pages-immunostaining-ihc-js": () => import("./../../../src/pages/immunostaining/ihc.js" /* webpackChunkName: "component---src-pages-immunostaining-ihc-js" */),
  "component---src-pages-immunostaining-ish-js": () => import("./../../../src/pages/immunostaining/ish.js" /* webpackChunkName: "component---src-pages-immunostaining-ish-js" */),
  "component---src-pages-immunostaining-js": () => import("./../../../src/pages/immunostaining.js" /* webpackChunkName: "component---src-pages-immunostaining-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-notifications-js": () => import("./../../../src/pages/notifications.js" /* webpackChunkName: "component---src-pages-notifications-js" */),
  "component---src-pages-pathology-map-js": () => import("./../../../src/pages/pathology_map.js" /* webpackChunkName: "component---src-pages-pathology-map-js" */),
  "component---src-pages-pathology-map-terms-js": () => import("./../../../src/pages/pathology_map_terms.js" /* webpackChunkName: "component---src-pages-pathology-map-terms-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-quantitative-ia-js": () => import("./../../../src/pages/quantitative_ia.js" /* webpackChunkName: "component---src-pages-quantitative-ia-js" */),
  "component---src-pages-referral-js": () => import("./../../../src/pages/referral.js" /* webpackChunkName: "component---src-pages-referral-js" */),
  "component---src-pages-slide-archiving-js": () => import("./../../../src/pages/slide_archiving.js" /* webpackChunkName: "component---src-pages-slide-archiving-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-telepathology-js": () => import("./../../../src/pages/telepathology.js" /* webpackChunkName: "component---src-pages-telepathology-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-whole-slide-scanning-js": () => import("./../../../src/pages/whole_slide_scanning.js" /* webpackChunkName: "component---src-pages-whole-slide-scanning-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

